import React from 'react';
import './App.css';
import {Layout} from "antd";
import Nav from "./nav/Header";
import Foot from "./nav/Footer";
import {Outlet} from "react-router-dom";
import {FloatingWhatsApp} from "react-floating-whatsapp";


function App() {
    return (
        <Layout>
            <Nav/>
            <Outlet/>
            <Foot/>
            <FloatingWhatsApp
                accountName={""}
                phoneNumber={"+254702040346"}
                avatar={"whatsapp.png"}
                statusMessage={"Typically replies in 30 minutes"}
            />

        </Layout>
    );
}

export default App;
