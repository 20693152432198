import React from "react";
import {Col, Row, Typography} from "antd";
import {Link} from "react-router-dom";

const {Text, Title} = Typography


const ConsultationBand = () => {
    return (
        <Row style={{
            height: '100px',
            backgroundColor: "#ecc719",
            width: "100%"
        }}
             align={"middle"}
             justify={"center"}
        >
            <Col>
                <Title level={4}>Would like a free consultation? <span><Link
                    to="mailto:info@brethrenconstructionltd.co.ke">Email Us</Link></span></Title>

            </Col>
        </Row>
    )
};

export default ConsultationBand;