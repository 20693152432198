import React from 'react';
import {Col, Image, Layout, Row, Typography, Carousel} from "antd";

const {Content} = Layout;
const {Title} = Typography;

interface ProjectI {
    title: string;
    img: string;
}




const Projects = () => {


    return (
        <Content style={{marginBottom: "20px"}}>
            <Title style={{textAlign: "center", marginTop: "5px"}} level={2}>Our Previous Projects</Title>
            <Carou/>
        </Content>
    );
};

const contentStyle: React.CSSProperties = {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

const Carou: React.FC = () => {
    return (

        <Row
            align={"middle"}
            justify={"center"}
        >
            <Carousel
                autoplay={true}
                style={{
                width:"500px"
                }}>
                {projects.map((project, index) => (
                    <div key={index}>
                        <Image  src={process.env.PUBLIC_URL + project.img} width={"500px"} alt={project.title}/>
                    </div>
                ))}
            </Carousel>
        </Row>

    );
};

const projects = [
    {
        title: 'Project 1',
        img: '1712555505734.jpg',
    },
    {
        title: 'Project 2',
        img: '1712555505806.jpg',
    },
    {
        title: 'Project 3',
        img: '1712555505913.jpg',
    },
    {
        title:'Project 4',
        img: '1714895841634.jpg'
    },
    // {
    //     title: 'Project 5',
    //     img: '1714895841459.jpg'
    // },
    // {
    //     title: 'Project 6',
    //     img: '1714895841692.jpg'
    // }
];
export default Projects;