import React from "react";
import TitleListComponent from "../main/TitleListComponent";
import WhatWeOffer from "../main/WhatWeOffer";
import {Layout} from "antd";

const {Content} = Layout;
const ProductsAndServices: React.FC = () => {
    return (
        <Layout style={{padding:"5px"}}>
            <Content>
                <TitleListComponent
                    props={{
                        title: 'SERVICES',
                        titleDesc: 'What We Offer',
                        data: services,
                        align: 'middle',
                        justify: 'center'

                    }}
                />
                <WhatWeOffer
                    props={{
                        rowNumber: 4,
                        isExpandable: true
                    }}
                />
            </Content>
        </Layout>
    )
}

export default ProductsAndServices;


const services = [
    {
        title: "Refurbishments",
        description: `
                    We handcraft our work to meet your
                    expectations, working with your to plan, design
                    and execute your renovation as you imagined it.
                    Our refurbishment expertise includes
                    residences, hotels, commercial offices,
                    warehouses and other establishments.
                    `

    },
    {
        title: "Design & Build, from Initial Sketches",
        description: `
                    We offer turnkey project delivery by bringing the
                    planning, design, project management, cost
                    management and construction of the project
                    together, under one roof.
                    Clients benefit from value-engineering and preconstruction reviews, accelerated
                    delivery, increased quality control, lower design
                    and consultation fees, reduced
                    owner risk and administrative responsibilities.
        `
    },
    {
        title: "Modern Interiors and Fit-Outs",
        description: `
                    Being a versatile company, Brethren
                    construction Ltd has vast experience in the
                    construction, extensions and refurbishments of
                    factories, warehouses and other industrial
                    establishments.
        `
    },
    {
        title: " Industrial and Civil",
        description: `
                    We have carried out interior fit-out work with
                    renowned designers, architects and clients,
                    combining functionality, economy and
                    aesthetics.
                    We ensure that your ideas and designs are
                    implemented into your space, with our
                    capabilities and experience enabling both small
                    and large scale fit-out projects to be carried out
                    successfully.
        `
    }
]